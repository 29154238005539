import React from 'react'
import Chimp from '../components/Chimp';

export default () => (
    <div className="footer sub-footer" style={{ marginBottom: 0, zIndex: 1 }}>
        <div className="container has-text-centered">
            <div className="has-text-white" style={{ paddingBottom: 40}}>
                <p style={{ marginTop: 20, marginBottom: 30 }}>
                    Signs of the Sojourner is available now for Windows and Mac. To receive updates, sign up for our mailing list!
                </p>
                <Chimp/>
            </div>
        </div>
    </div>
)
