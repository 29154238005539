import React from 'react'

export default ({ parentClass = '', children }) => (
    <section className="section is-main-content">
        <div className="container">
            <div className={parentClass}>
                {children}
            </div>
        </div>
    </section>
)
