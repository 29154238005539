import React from 'react'
import Helmet from 'react-helmet'
import Navbar from './Navbar'
import favicon from '../img/favicon.png'
import Footer from './Footer'
import FooterDog from './FooterDog'

export default ({ title, description, location, children }) => (
    <>
        <Helmet
            title={`${title} | Echodog Games`}
            link={[
                { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
            ]}
        >
            <html lang="en"/>
            <meta name="description" content={description}/>
        </Helmet>
        <div className="is-main-parent">
            <Navbar location={location}/>
            <div className="navbar-padding-fix"></div>
            {children}
            <Footer style={{ zIndex: 1 }}>
                <FooterDog/>
            </Footer>
        </div>
    </>
)
