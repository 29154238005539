import { Link } from 'gatsby'
import React, { useState } from 'react'
import SocialIcon from './SocialIcon'
import { ReactComponent as Logo } from '../img/theechodoghead.svg'
import { ReactComponent as TextLogo } from '../img/logo_text.svg'
import {
    faSteam,
    faDiscord,
    faTwitter,
    faFacebook,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'

const nav = [
    { text: 'Signs of the Sojourner', link: '/', regex: /^\/$/ },
    { text: 'Dev Blog', link: '/blog', regex: /^\/blog.*$/ },
    { text: 'About', link: '/about', regex: /^\/about\/?$/  }
]

const social = [
    { icon: faSteam, link: 'https://store.steampowered.com/app/1058690/Signs_of_the_Sojourner', name: 'Steam' },
    { icon: faDiscord, link: 'https://discord.gg/echodoggames', name: 'Discord' },
    { icon: faTwitter, link: 'https://twitter.com/EchodogGames', name: 'Twitter' },
    { icon: faFacebook, link: 'https://facebook.com/echodogdevs', name: 'Facebook' },
    { icon: faInstagram, link: 'https://instagram.com/echodoggames', name: 'Instagram' },
]

export default ({ location: { pathname }}) => {
    const [showingMenu, setShowingMenu] = useState(false)
    const toggleMenu = () => setShowingMenu(!showingMenu)
    const activeClass = () => showingMenu ? ' is-active' : ''

    return (
        <nav className="navbar is-fixed-top is-dark">
            <div className="container">
                <div className="navbar-brand">
                    <div className="navbar-item is-hidden-desktop"></div>
                    <Link className="navbar-item" to="/" aria-label="Home">
                        <Logo className="is-hidden-tablet" hidden width="28px" height="100%"/>
                        <TextLogo className="is-hidden-mobile" width="150px" height="100%"/>
                    </Link>
                    <div className="navbar-item"></div>
                    {social.map(icon =>
                        <div
                            className="navbar-item has-text-centered is-hidden-tablet"
                            key={icon.link}
                        >
                            <SocialIcon icon={icon} iconSize="lg" name={icon.name}/>
                        </div>
                    )}
                    <div
                        role="button"
                        className={`navbar-burger burger${activeClass()}`}
                        aria-label="menu"
                        aria-expanded="false"
                        onClick={toggleMenu}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </div>
                </div>
                <div className={`navbar-menu${activeClass()}`}>
                    <div className="navbar-start">
                        {nav.map(({ text, link, regex }) =>
                            <Link
                                className={`navbar-item is-tab ${regex.test(pathname) ? 'is-active' : ''}`}
                                key={link}
                                to={link}
                                activeClassName=""
                            >
                                { text }
                            </Link>
                        )}
                        <a
                            className="navbar-item is-tab"
                            href="/presskit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        Press
                        </a>
                    </div>
                    <div className="navbar-end is-hidden-mobile">
                        {social.map(icon =>
                            <div
                                className="navbar-item has-text-centered"
                                key={icon.link}
                            >
                                <SocialIcon
                                    icon={icon}
                                    iconSize="lg"
                                    name={icon.name}
                                />
                            </div>
                        )}
                        <div className="navbar-item is-hidden-desktop"></div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
