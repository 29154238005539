import React from 'react'
import BareLayout from './BareLayout'
import MainContent from './MainContent'
import SimpleChimp from './SimpleChimp'

export default ({ title, description, location, parentClass = '', children }) => (
    <BareLayout title={title} location={location} description={description}>
        <MainContent parentClass={parentClass}>
            {children}
        </MainContent>
        <SimpleChimp/>
    </BareLayout>
)
