import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ icon, iconSize, containerSize, name }) => {
    const cs = 'icon has-text-primary'
    const is = `fab fa-${icon.icon}`
    const containerClass = containerSize ? `${cs} is-${containerSize}` : cs
    const iconClass = iconSize ? `${is} fa-${iconSize}` : is
    return (
        <a className={containerClass} href={icon.link} aria-label={name} target="_blank" rel="nofollow noopener noreferrer">
            <FontAwesomeIcon className={iconClass} icon={icon.icon}/>
        </a>
    )
}
