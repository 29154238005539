import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default ({ focus }) => {
    const [value, setValue] = useState('')
    return (
        <form
            className="field"
            action="https://echodoggames.us19.list-manage.com/subscribe/post?u=0aacddff348a834aa2fc17909&amp;id=1613398b8e"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            novalidate
        >
            <div className="field has-addons">
                <div className="control has-icons-left is-expanded">
                    <input
                        className="input"
                        name="EMAIL"
                        id="mce-EMAIL"
                        type="email"
                        placeholder="email@goes.here"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        autoFocus={focus}
                        aria-label="Mailing list signup"
                    />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </span>
                </div>
                <div className="control">
                    {/* This thing */}
                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_0aacddff348a834aa2fc17909_1613398b8e" tabindex="-1" value=""/></div>
                    <input
                        className="button is-primary"
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                    />
                </div>
            </div>
        </form>
    )
}
